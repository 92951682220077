import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import moment from 'moment';
import { Table, Select, DatePicker, Spin, Input, Empty, Button} from 'antd';
import { ArrowRightOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Reports = () => {

  const [loading, setLoading] = useState(true)
  const [reportList, setReportList] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [selectedReport, setSelectedReport] = useState([])
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedVenue, setSelectedVenue] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedGameType, setSelectedGameType] = useState('')

  useEffect(() => {
    getReports()
    getVenues()
  }, []);

  useEffect(() => {
    setSelectedDate('')
    setSelectedMonth('')
    setSelectedVenue('')
    setSelectedCity('')
    setSelectedGameType('')
  }, [selectedReport])

  const getReports = async () => {
    await getData('/business/get_reports_list/')
    .then(response =>{
      setReportList(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }
  
  const getResults = (report) => {
    if(report.parameters.length == 0){
      const url = `https://api.sporthood.in/report/${report.report_no}`
      window.open(url, '_blank'); 
    }
    else{
      setSelectedReport(report)
      setShowForm(true)
    }
  }

  const closeForm = () => {
    setSelectedReport([])
    setShowForm(false)
  }

  const onChangeDate = (date, dateString) => {
    setSelectedDate(dateString)
  };

  const onChangeMonth = (date, dateString) => {
    setSelectedMonth(dateString)
  }

  const openReport = (report) =>{
    var report_no = report.report_no
    var parameters = report.parameters

    let url = `https://api.sporthood.in/report/${report_no}/?`;

    parameters.forEach((param, index) => {
      const value = getValuesForArguments(param); 

      if (value !== null) {
        url += `${param}=${value}`;
        
        if (index < parameters.length - 1) {
          url += '&';
        }
      }
    });
    window.open(url, '_blank'); 
    closeForm()
  }

  const getValuesForArguments = (parameter) => {
    if(parameter == 'date'){
      return selectedDate
    }
    else if(parameter == 'month'){
      return selectedMonth
    }
    else if(parameter == 'venue_id'){
      return selectedVenue
    }
    else if(parameter == 'city'){
      return selectedCity
    }
    else if(parameter == 'game_type_id'){
      return selectedGameType
    }
    else{
      return null
    }
  }

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header>          
          <span>Reports</span>
        </Styled.Header>
       
      {
        showForm ?
        <Styled.Body>
          {selectedReport &&
            <Styled.Form className ='col-md-8'>
              <Styled.FormHeading>
                <ArrowLeftOutlined onClick={()=>closeForm()} style={{cursor:'pointer'}}/>
                <span>Enter Details for Report</span>
                <div></div>
              </Styled.FormHeading>
              
              <Styled.FormOption>
                <div className='col-md-1'> </div> 
                <Styled.Option className='col-md-4'>Report Name</Styled.Option>
                <div className='col-md-7'>{selectedReport.report_title}</div>
              </Styled.FormOption>
              
              {
                selectedReport.parameters.map(x=>(
                    x === 'date' ? 
                      <Styled.FormOption>
                        <div className='col-md-1'> </div> 
                        <Styled.Option className='col-md-4'>Select Date</Styled.Option>
                        <DatePicker className='col-md-7' onChange={onChangeDate} />
                      </Styled.FormOption>
                  :
                    x === 'venue_id' ? 
                      <Styled.FormOption>
                        <div className='col-md-1'> </div> 
                        <Styled.Option className='col-md-4'>Select Venue</Styled.Option>
                        <Select
                          showSearch
                          className='col-md-7'
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          onChange={(input,option)=>setSelectedVenue(option.value)}
                          value={selectedVenue}
                          filterOption={(input, option) => option.children.includes(input)}
                        >
                          <Option value='' key=''>Choose Venue</Option>
                          {
                            venues.map(item=>(
                              <Option value={item.id} key={item.id}>{item.name}</Option>
                            ))
                          }
                         
                        </Select>
                      </Styled.FormOption>
                  :
                    x === 'city' ? 
                      <Styled.FormOption>
                        <div className='col-md-1'> </div> 
                        <Styled.Option className='col-md-4'>Select City</Styled.Option>
                        <Select
                          showSearch
                          className='col-md-7'
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          onChange={(input,option)=>setSelectedCity(option.value)}
                          value={selectedCity}
                          filterOption={(input, option) => option.children.includes(input)}
                        >
                          <Option value='' key=''>Choose City</Option>
                          {
                            cities.map(item=>(
                              <Option value={item.name} key={item.name}>{item.name}</Option>
                            ))
                          }
                         
                        </Select>
                      </Styled.FormOption>
                  :
                    x === 'game_type_id' ? 
                      <Styled.FormOption>
                        <div className='col-md-1'> </div> 
                        <Styled.Option className='col-md-4'>Select Gametype</Styled.Option>
                        <Select
                          className='col-md-7'
                          placeholder="select game type" 
                          onChange={(input,option)=>setSelectedGameType(option.value)}
                          value = {selectedGameType}
                        >                      
                          <Option value=''>choose Game Type</Option>
                          <Option value={8}>Football Academy</Option>
                        </Select>
                      </Styled.FormOption>
                  :
                    x === 'month' ? 
                      <Styled.FormOption>
                        <div className='col-md-1'> </div> 
                        <Styled.Option className='col-md-4'>Enter Month</Styled.Option>
                        <DatePicker className='col-md-7' picker="month" onChange={onChangeMonth} />
                      </Styled.FormOption>
                  :
                    null
                  ))
              }
            
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
              <Button style={{backgroundColor:'#047294',color:'white',borderColor:'#047294'}} onClick={()=>openReport(selectedReport)}>Submit</Button>
            </div>

        
        </Styled.Form>
      }
        </Styled.Body>

        :
        <>
          {(reportList && reportList.length) > 0 ?
            <Styled.MainDiv>
               { reportList.map((x,index)=>(
                  <Styled.CategoryDiv>
                    <Styled.CategoryHeading>{x.category}</Styled.CategoryHeading>
                    <Styled.ReportDiv>
                    <ul>
                      {
                        x.reports.map(report => (
                          <Styled.ReportName onClick={()=>getResults(report)}>
                            {report.report_title}
                            <ArrowRightOutlined style={{ marginLeft: '20px'}}/>
                          </Styled.ReportName>
                          ))
                      }
                      </ul>
                    </Styled.ReportDiv>
                  </Styled.CategoryDiv>
                ))
              }
            </Styled.MainDiv>
            :
            <Empty style={{marginTop: '10%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
          }
        </>
      }

      </Styled.MainContainer>
    </Spin>
  );
}

export default Reports;