import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : center;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

