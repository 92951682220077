import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
  user-select: none;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
	display: flex;
  flex-direction: column;	
`;

export const StatsDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
`;

export const StatsBox = styled.div`
	width: 27vw;
	height: 12vh;
	padding: 10px;
	border-radius: 5px;
	background-color: white;
	display: grid;
	grid-template-columns: 25% 75%;
	align-items: center;
`;

export const IconDiv = styled.div`
	display: flex;
	justify-content: center;
`
export const LabelDiv = styled.div`
	display: flex;
	flex-direction:column;
	margin-left: 30px;
`

export const IconLabelDiv = styled.div`
	display: flex;
	align-items: center;
`;

export const StatsLabel = styled.div`
	font-size: 14px;
	color: grey;
`;

export const StatsAmountDiv = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const AmountLabel = styled.div`
	font-size: large;
`;

export const AmountGraph = styled.div`
	display: flex;
	align-items: center;
	padding: 3px 7px;
	border-radius: 10px;
`;

export const AmountGraphLabel = styled.div`
	color: green;
	margin-left: 5px;
`;


export const GraphDiv = styled.div`
	display: grid;
	grid-template-columns: 60% 2% 38%;
	margin-top: 20px;
	height: 40vh;
`;

export const ChartDiv = styled.div`
	display: grid;
	grid-template-columns: 25% 75%;
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	margin-top: 20px;
`

export const LabelContainer = styled.div`
	display: grid;
	grid-template-columns: 25% 25% 25%;
`

export const GraphDivLeft = styled.div`
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	height: 40vh;
`;

export const GraphDivRight = styled.div`
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	height: 40vh;
	flex-direction: column;
	position: relative;
`;

export const GraphDivLabelDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
`;

export const LabelHeading = styled.div`
	font-size: 15px;
`;

export const GraphMonthSelector = styled.div`
	padding: 7px;
	border-radius: 5px;
	border: 1px solid whitesmoke;
	font-size: 12px;
	color: grey;
`;

export const PieDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const PieDivInfo = styled.div`
	justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	
`;

export const InfoColor = styled.span`
	width: 15px;
	height: 15px;
	margin-right: 10px;
`;

export const InfoLabel = styled.div`
	font-size: 13px;
`;


export const TotalAmount = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;


