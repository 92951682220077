import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 15px;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
	display: flex;
  flex-direction: column; 
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : center;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const GraphContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 2% 49%;
  height: 38vh;
`;

export const GraphDiv = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;  
  margin-bottom: 10px;
`;

export const GraphDivLabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const LabelHeading = styled.div`
  font-size: 15px;
  color: #02475c;
`;

export const GraphMonthSelector = styled.div`
  padding: 7px;
  border-radius: 5px;
  border: 1px solid whitesmoke;
  font-size: 12px;
  color: grey;
`;

export const Icon = styled.a`
  cursor: pointer;
  color: grey;
  font-size: large;

  &:hover {
    color: #02475c;
    font-weight: bold; 
    transition: transform 0.3s ease;
  }
`;

export const PercentDiv = styled.div`
  padding: 3px 5px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
`
